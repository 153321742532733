import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Weidenpesch: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Weidenpesch,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Weidenpesch"
          points="534,448 542,466 538,472 530,478 526,494 519,496 509,472 505,472 505,480 499,484 483,474 473,472 471.3,473.4 437,432 453,422 487,424 503,422 513,428 518,422 540,444"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 454.2006 446.3831)"
        >
          Weidenpesch
        </text>
      </g>
    </>
  );
});
